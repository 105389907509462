import ServiceCard from "./ServiceCard"

const Service = () => {
  const services = [
    {
      title: "Custom Software Development",
      icon: "/development.png",
      type: "white"
    },
    {
      title: "Mobile App Development",
      icon: "/mobile-application.png",
      type: "blue"
    },
    {
      title: "UI/UX Design",
      icon: "/design-line.png",
      type: "white"
    },
    {
      title: "Cloud Solutions",
      icon: "/cloud.png",
      type: "blue"
    },
    {
      title: "Software Integration",
      icon: "/api-key.png",
      type: "white"
    },
    {
      title: "Business Intelligence and Analytics",
      icon: "/ai.png",
      type: "blue"
    }
  ]

  return (
    <div className="bg-[#F6F8F7] py-20 -mt-5 lg:mt-0">
      <div className="w-full lg:w-[1200px] px-5 lg:px-0 mx-auto">
        <div className="text-[30px] lg:text-[36px] text-[#292E3D] text-center font-bold">Services</div>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-20 mt-10 px-0 lg:px-20">
          {services.map((service, index) => (
            <ServiceCard service={service} key={index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Service