const ServiceCard = ({ service }) => {
  return (
    <div 
      style={{ boxShadow: "0px 0px 37px rgba(17, 24, 39, 0.1)" }}
      className={`h-[181px] rounded-[4px] px-5 flex items-center justify-center ${service.type === "white" ? "bg-white" : "bg-[#336797]"}`}
    >
      <div className="text-center">
        <img src={service.icon} className="w-8 h-8 mx-auto mb-5" />
        <span className={`inline-block h-[2px] w-[60px] mb-5 bg-[#E0E3EB]`}></span>
        <div 
          className={`text-[20px] leading-[24px] tex-center mx-auto font-semibold ${service.type === "white" ? "text-[#336797]" : "text-white"}`}
        >
          {service.title}
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;