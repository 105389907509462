import ExperienceCard from "./ExperienceCard";

const Experience = () => {
  const experiences = [
    {
      icon: "/handshake.png",
      iconPosition: "top-[-15px] right-[-20px]",
      iconSize: "w-[97px]",
      count: 142,
      description: "Happy Clients",
      cardPosition: "-top-5 lg:top-0 right-[30px] lg:right-[150px] z-20",
      cardSize: "w-[280px]"
    },
    {
      icon: "/paper.png",
      iconPosition: "top-[-50px] left-[-20px]",
      iconSize: "w-[79px]",
      count: 72,
      description: "Projects Completed",
      cardPosition: "top-[100px] left-[50px] lg:left-[80px]",
      cardSize: "w-[361px]"
    },
    {
      icon: "/trophy.png",
      iconPosition: "top-[-15px] right-[-20px]",
      iconSize: "w-[93px]",
      count: 37,
      description: "Awards Win",
      cardPosition: "bottom-[200px] lg:bottom-[70px] right-[30px] lg:right-[200px] z-20",
      cardSize: "w-[276px]"
    },
    {
      icon: "/draft.png",
      iconPosition: "bottom-[-50px] right-[-30px]",
      iconSize: "w-[84px]",
      count: 30,
      description: "Years in Business",
      cardPosition: "bottom-[100px] lg:bottom-[-30px] left-[20px] lg:left-[150px]",
      cardSize: "w-[333px]"
    }
  ]
  return (
    <div className="py-[100px] mb-12">
      <div className="w-full lg:w-[1200px] px-5 lg:px-0 mx-auto block lg:flex">
        <div 
          // style={{width: "calc(100% - 360px)"}}
          className="w-full h-[600px] lg:h-auto lg:w-calc-100%+360px relative"
        >
          {experiences.map((experience, index) => (
            <ExperienceCard key={index} experience={experience} />
          ))}
        </div>
        <div className="w-full lg:w-[360px] px-5 lg:px-0 shrink-0">
          <div className="text-[42px] lg:text-[60px] leading-[44px] lg:leading-[66px] font-bold text-[#336797] mb-10">30 Years Experience</div>
          <div className="text-[18px] text-[#525B7A] leading-[30px] mb-10">
            PT CITRA INTERNATIONAL DIGITAL has a professional team of experienced engineers, designers, and project managers dedicated to meeting the diverse needs of our clients through technological innovation and excellent service.
          </div>
          <a href="#" className="inline-block w-[176px] h-[53px] leading-[53px] bg-[#336797] text-center text-white rounded-[2px] text-[18px] font-semibold">
            Contact Us
          </a>
        </div>
      </div>
    </div>
  )
}

export default Experience;