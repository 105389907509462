const Footer = () => {
  return (
    <footer className="pt-[80px]">
      <div className="w-full lg:w-[1200px] px-5 lg:px-0 mx-auto block lg:flex justify-between">
        <div className="w-full lg:w-[50%]">
          <p className="text-[16px] lg:text-[20px] text-[#292E3D] flex">
            <label className="inline-block w-[100px] shrink-0 text-[16px] lg:text-[20px] text-[#336797]">ADDRESS</label>:
            <span className="inline-block ml-2">
              The Suite Tower Pantai Indah Kapuk 7th Floor (Union Space) Jkt Outer Ring Road No.17, RT.7/RW.2, Kamal Muara, Kecamatan. Penjaringan, Jakarta Utara, Daerah Khusus Ibukota Jakarta 14470
            </span>
          </p>
          <p className="text-[16px] lg:text-[20px] text-[#292E3D]">
            <label className="inline-block w-[100px] shrink-0 text-[16px] lg:text-[20px] text-[#336797]">PHONE</label>:
            +62 81584446170
          </p>
          <p className="text-[16px] lg:text-[20px] text-[#292E3D]">
            <label className="inline-block w-[100px] shrink-0 text-[16px] lg:text-[20px] text-[#336797]">EMAIL</label>:
            jeffrey@inter-digital.org
          </p>
          {/* <div className="flex mt-8">
            <img src="/cid-logo.png" alt="CID" className="w-[57px] h-[57px] mr-3"/>
            <div className="text-[20px] font-bold text-[#336797] leading-[26px]">
              PT Citra <br/> International Digital
            </div>
          </div> */}
        </div>
        <div>
          <div className="flex mt-8">
            <img src="/cid-logo.png" alt="CID" className="w-[57px] h-[57px] mr-3"/>
            <div className="text-[20px] font-bold text-[#336797] leading-[26px]">
              PT Citra <br/> International Digital
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#336797] h-[70px] mt-[70px]">
        <div className="w-full lg:w-[1200px] px-5 lg:px-0 h-[70px] mx-auto flex items-center text-[16px] text-white">
          PT Citra International Digital © 2024 All Rights Reserved
        </div>
      </div>
    </footer>
  )
}

export default Footer;