const About = () => {
  return (
    <div className="w-full lg:w-[1200px] md:w-full md:px-5 mx-auto pt-12 mb-0 lg:mb-[150px] relative mt-10 lg:mt-0">
      <img src="/about.png" className="max-w-calc-100%+32px w-calc-100%+32px -ml-4 lg:ml-0 lg:w-[902px] h-[400px] lg:h-[669px] mt-[200px] lg:mt-0" alt="about" />
      <div className="bg-[#336797e3] w-[85%] lg:w-[488px] rounded-[2px] p-8 lg:p-12 absolute top-0 lg:top-[50%] lg:right-0 transform lg:-translate-y-1/2 left-[50%] -translate-x-1/2 lg:left-auto lg:translate-x-0 ">
        <div className="text-white text-bold text-[30px] lg:text-[36px] font-bold mb-4 lg:mb-7">About us</div>
        <div className="text-[18px] leading-6 text-white mb-10">
          PT CITRA INTERNATIONAL DIGITAL is a leading Internet IT company specializing in instant messaging (IM) systems and software and website outsourcing services.
          <br/><br/>
          Headquartered in Shenzhen, China, we are industry pioneers, providing efficient, secure, and stable communication solutions, as well as customized software and website development services for clients worldwide.
        </div>
        <a href="#" className="inline-block h-[53px] leading-[53px] px-5 bg-white rounded-[6px] text-[#336797] text-[18px] font-semibold">
          More on Our History
        </a>
      </div>
    </div>
  );
}

export default About;