import { useRef } from 'react';

import Header from "./components/Header";
import HeroSection from "./components/Hero";
import OurFeature from "./components/OurFeature";
import About from "./components/About";
import Service from "./components/Service";
import Experience from "./components/Experience";
import Consultation from "./components/Consultation";
import ContactForm from "./components/ContactForm";
import Footer from "./components/Footer";

export default function Home() {
  const heroRef = useRef(null);
  const featuresRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const experienceRef = useRef(null);
  const consultationRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <main className="overflow-x-hidden">
      <Header 
        heroRef={heroRef} 
        featuresRef={featuresRef} 
        aboutRef={aboutRef} 
        servicesRef={servicesRef} 
        experienceRef={experienceRef} 
        consultationRef={consultationRef} 
        contactRef={contactRef} 
      />
      <section ref={heroRef}><HeroSection /></section>
      <section ref={featuresRef}><OurFeature /></section>
      <section ref={aboutRef}><About /></section>
      <section ref={servicesRef}><Service /></section>
      <section ref={experienceRef}><Experience /></section>
      <section ref={consultationRef}><Consultation /></section>
      {/* <section ref={contactRef}><ContactForm /></section> */}
      <Footer />
    </main>
  );
}
