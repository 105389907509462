import { set } from 'lodash';
import { useState } from 'react';

const Header = ({ heroRef, featuresRef, aboutRef, servicesRef, experienceRef, consultationRef, contactRef }) => {
  const [isOpen, setIsOpen] = useState(false);
  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <header className="z-50 w-full h-[80px] relative">
      <div className="lg:w-[1200px] w-full px-5 lg:px-0 mx-auto h-full flex items-center justify-between">
        <div className='flex'>
          <img src="/cid-logo.png" className='w-10 h-10 lg:w-[50px] lg:h-[50px]' alt="CID" />
          <div className='text-[16px] leading-[16px] lg:text-[20px] text-[#336797] lg:leading-[20px] font-bold ml-2'>
            PT Citra <br/> International Digital
          </div>
        </div>
        <div className="block lg:hidden cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
          <img src="/hamburger.png" alt="menu" className="w-6 h-6" />
        </div>
        <nav 
          className={`block lg:block ${isOpen ? 'block' : 'hidden'} absolute lg:relative top-[80px] lg:top-0 left-0 lg:left-auto bg-white w-full lg:w-auto lg:bg-transparent shadow-lg lg:shadow-none p-5 lg:p-0`}
        >
          <ul className='block lg:flex'>
            <li 
              className='text-[#14171F] text-[18px] ml-10 cursor-pointer leading-10'
              onClick={() => {
                scrollTo(heroRef)
                setIsOpen(!isOpen)
              }}
            >
              Home
            </li>
            <li 
              className='text-[#14171F] text-[18px] ml-10 cursor-pointer leading-10' 
              onClick={() => {
                scrollTo(aboutRef)
                setIsOpen(!isOpen)
              }}
            >
              About Us
            </li>
            <li 
              className='text-[#14171F] text-[18px] ml-10 cursor-pointer leading-10' 
              onClick={() => {
                scrollTo(servicesRef)
                setIsOpen(!isOpen)
              }}
            >
              Services
            </li>
            {/* <li className='text-[#14171F] text-[18px] ml-10 cursor-pointer' onClick={() => scrollTo(contactRef)}>Contact</li> */}
          </ul>
        </nav>
      </div>
    </header>
  )
}

export default Header;