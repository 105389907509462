import { Parallax } from 'react-parallax';

const HeroSection = () => {
  return (
    <div className='h-[500px] lg:h-[646px] relative'>
      <Parallax bgImage="/hero2.png" strength={500} className="parallax-hero">
        <div className='absolute top-0 left-0 z-10 w-full h-[500px] lg:h-[646px] bg-gradient-to-r from-white to-transparent'></div>
        <div className='w-full px-5 lg:px-0 lg:w-[1200px] mx-auto relative z-20 h-[500px] lg:h-[646px] flex items-end'>
          <h2 className='w-[80%] text-[40px] leading-[42px] lg:text-[72px] font-[600] lg:leading-[80px] text-[#14171F] mb-20'>Pioneering the Future with Cutting-Edge AI and Crypto Solutions</h2>
        </div>
      </Parallax>
    </div>
  )
}

export default HeroSection;