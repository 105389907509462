import ReputationCard from "./ReputationCard";

const OurFeature = () => {
  const features = [
    {
      icon: "/cs.png",
      title: "Best Services",
      description: "We deliver top-tier services that exceed expectations, ensuring satisfaction through every interaction."
    },
    {
      icon: "/team.png",
      title: "Best Teams",
      description: "Our dedicated teams are composed of industry experts who collaborate to achieve outstanding results. "
    },
    {
      icon: "/design.png",
      title: "Best Designs",
      description: "We create visually stunning designs that captivate and engage, reflecting the highest standards of creativity."
    }
  ]
  return (
    <div className="w-full lg:w-[1200px] px-5 lg:px-0 mx-auto py-[30px] lg:py-[60px]">
      <div className="text-[30px] lg:text-[36px] text-[#292E3D] font-bold mb-[50px] text-center">Our Reputation</div>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 lg:gap-10">
        {features.map((feature, index) => (
          <ReputationCard
            key={index}
            icon={feature.icon}
            title={feature.title}
            description={feature.description} 
          />
        ))}
      </div>
    </div>
  )
}

export default OurFeature;