const ReputationCard = ({icon, title, description}) => {
  return (
    <div className="h-[220px] text-center lg:text-left border border-[#e0e3eb] rounded-[4px] p-5">
      <img src={icon} alt="icon" className="w-[36px] h-[36px] mb-4 mx-auto lg:mx-0"/>
      <div className="text-[20px] text-[#3D445C] font-bold mb-4">{title}</div>
      <div className="text-[16px] text-[#858EAD]">{description}</div>
    </div>
  )
}

export default ReputationCard;