const ExperienceCard = ({ experience }) => {
  return (
    <div 
      style={{ boxShadow: "0px 12px 16px rgba(17, 24, 39, 0.1)" }} 
      className={`bg-white absolute p-[30px] pr-[70px] ${experience.cardPosition}`}
    >
      <img 
        src={experience.icon}
        alt="icon" 
        className={`absolute ${experience.iconPosition} ${experience.iconSize}`}
      />
      <div className="text-[48px] lg:text-[72px] leading-[72px] text-[#292E3D] font-bold mb-0 lg:mb-2">
        {experience.count}
      </div>
      <div className="text-[20px] lg:text-[24px] leading-[24px] pl-4 ml-3 text-[#667299] border-l-[4px] lg:border-l-[7px] border-[#F9995D]">
        {experience.description}
      </div>
    </div>
  )
}

export default ExperienceCard;