const Consultation = () => {
  return (
    <div className="h-[300px] relative flex items-center">
      <img src="/consult-bg.png" alt="consultation" className="absolute left-0 top-0 w-full h-full object-cover"/>
      <div className="w-full lg:w-[1200px] px-5 lg:px-0 mx-auto block lg:flex justify-between items-center text-center lg:text-left">
        <div className="relative mb-10 lg:mb-0">
          <div className="text-white text-[30px] lg:text-[36px] font-bold mb-4 text-center lg:text-left">Free consultation with exceptional quality</div>
          <div className="text-[20px] lg:text-[24px] leading-6 text-white text-center lg:text-left">Just one call away: +62 81584446170</div>
        </div>
        <a 
          href="#"
          className="h-[53px] relative leading-[53px] inline-block rounded-[2px] text-white px-5 text-[18px] font-semibold border border-white"
        >
          Get your consultation
        </a>
      </div>
    </div>
  )
}

export default Consultation;